class ServerRequest {
  API_URL_AUTH = 'https://api.ai-dente.com/api/auth';
  API_URL_GENERATE_RECIPE = 'https://api.ai-dente.com/api/generate-recipe';
  API_URL_CHANGE_LIKE = 'https://api.ai-dente.com/api/like';
  API_URL_GET_FAVORITES = 'https://api.ai-dente.com/api/favorites';
  API_KEY = "JNuNVUXLPj0iKV99";

  getHeaders() {
    return {
      "api-key": this.API_KEY,
      "Content-Type": "application/json",
    };
  }

  getFavoritesRecipes(userHash, callback) {
    fetch(`${this.API_URL_GET_FAVORITES}?user_hash=${userHash}`, {
      method: "GET",
      headers: this.getHeaders(),
    }).then(response => {
      return response.json();
    }).then(callback);
  }

  async authenticateUserData(number) {
    const response = await fetch(this.API_URL_AUTH, {
      method: "POST",
      body: JSON.stringify({number: number, "geo": "EN", "aggregator": "test"}), // Відправляємо requestData
      headers: this.getHeaders(),
    })
    return response;
  }

  async createRecipeData(banner, resource, recipeData, user_hash, except_titles) {
    let data = {
      'banner': banner,
      "resource": resource,
      "recipeData": recipeData,
      "user_hash": user_hash,
      "except_titles": except_titles
    }
    const response = await fetch(this.API_URL_GENERATE_RECIPE, {
      method: "POST",
      body: JSON.stringify(data),
      headers: this.getHeaders(),
    })
    return response;
  }

  async changeFavoriteRecipeData(recipe_id, like) {
    const response = await fetch(this.API_URL_CHANGE_LIKE, {
      method: "PUT",
      body: JSON.stringify({
        "recipe_id": recipe_id,
        "like": Number(like)
      }),
      headers: this.getHeaders(),
    })
    return response;
  }
}

export default new ServerRequest();